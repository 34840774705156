.centered {
	display: table;

	center {
		display: table-cell;
		vertical-align: middle;
	}
}

.center {
	text-align: center;
}

.cover {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.uppercase {
	text-transform: uppercase;
}

.clear {
	&:after {
		content: '';
		display: block;
		clear: both;
		*zoom: 1;
	}
}

.fill {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.absolute {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.v-wrap{
    height: 100%;
    white-space: nowrap;
    text-align: center;
}
.v-wrap:before{
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    margin-right: -.25em;
    height: 100%;
}
.v-box {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;

	&.left-align {
		text-align: left;
	}
}

.fixed {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.reset {
	position: inherit;
	top: inherit;
	bottom: inherit;
	left: inherit;
	right: inherit;
	z-index: inherit;
}

.icon {
	content: '';
	// font-family: $font-icon;
}

.red {
	background-color: $color-red;
	transition: all $transition-out ease-in-out;

	&:hover {
		background-color: #C91437;
		// transition: all $transition-in ease-in;
	}
}

.red-mid {
	background-color: $color-red-mid;
	transition: all $transition-out ease-in-out;

	&:hover {
		background-color: #9D0B28;
		// transition: all $transition-in ease-in;
	}
}

.red-dark {
	background-color: $color-red-dark;
	transition: all $transition-out ease-in-out;

	&:hover {
		background-color: #4F0000;
		// transition: all $transition-in ease;
	}
}

.red-text {
	color: $color-red;
}

.full-width {
	width: 100%;
	float: left;
}

.white-text {
	color: #fff;
}

.dark-text {
	color: $color-dark;
}


.light-grey {
	background-color: $color-lightGrey;
	transition: all $transition-out ease-out;
}

.image-container {
	position: relative;

	.image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-size: cover;
		background-position: center;
	}
}

.videoWrapper {
    position:absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    iframe {
        position:absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.mobile-hide {
	@media (max-width: 767px) {
		display: none;
	}
}
