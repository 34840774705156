@font-face {
    font-family: "TradeGothic-BoldCondTwenty";
    src: url("fonts/TradeGothic-BoldCondTwenty.eot");
    src: url("fonts/TradeGothic-BoldCondTwenty.eot?#iefix") format("embedded-opentype"),
         url("fonts/TradeGothic-BoldCondTwenty.woff2") format("woff2"),
         url("fonts/TradeGothic-BoldCondTwenty.woff") format("woff"),
         url("fonts/TradeGothic-BoldCondTwenty.ttf") format("ttf"),
         url("fonts/TradeGothic-BoldCondTwenty.svg#TradeGothic-BoldCondTwenty") format("svg");
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: "mates-social";
    src: url("fonts/mates-social.eot");
    src: url("fonts/mates-social.eot?#iefix") format("embedded-opentype"),
         url("fonts/mates-social.woff2") format("woff2"),
         url("fonts/mates-social.woff") format("woff"),
         url("fonts/mates-social.ttf") format("ttf"),
         url("fonts/mates-social.svg#mates-social") format("svg");
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: "mates";
    src: url("fonts/mates.eot");
    src: url("fonts/mates.eot?#iefix") format("embedded-opentype"),
         url("fonts/mates.woff2") format("woff2"),
         url("fonts/mates.woff") format("woff"),
         url("fonts/mates.ttf") format("ttf"),
         url("fonts/mates.svg#mates") format("svg");
    font-style: normal;
    font-weight: 300;
}