// RESPONSIVE
$small: 380px;
$mobile: 620px;
$tablet: 768px;
$desktop: 1024px;
$large: 1280px;

// CONTAINER
$container-min: 320px;
$container-max: 1270px;
$container-inner-min: 320px;
$container-inner-max: 740px;
$container-inner-medium-min: 320px;
$container-inner-medium-max: 800px;
$container-padding: 20px;
$container-padding-tablet: 20px;
$container-padding-mobile: 20px;

// COLORS

$color-red: #ed1941;
$color-red-mid: #b90d30;
$color-red-hover: #8b011d;
$color-red-dark: #5e0000;
$color-body: #292929;
$color-dark: #171717;
$color-grey: #c2c2c2;
$color-lightGrey: #f7f7f7;
$color-borderGrey: #e7e7e7;
$color-buttonGrey: #e4e4e4;
$color-darkgrey: #727272;
$color-social-grey: #646464;
$color-link-resting: #316a88;
$color-link-hover: #18455c;
$color-link-visited: #292929;
$color-input-focus: #488db6;


$color-lightRed: #f17c6a;
$color-lightBlue: #7cbcd9;
$color-darkYellow: #ebc75b;
$color-yellow: #efd06c;
$color-productGrey: #f2f2f2;
$color-input: #f1f2f2;
$color-peach: #fdcdcd;
$color-placeholder: rgba($color-dark, 0.5);
$color-green: #006b54;
$color-yellow: #ffd100;
$color-lightYellow: #ffd149;
$color-blue: #638792;
$color-lightgrey: #d5d5d5;
$color-darkgrey: #666;
$color-light: #fff;
$color-bg: #f4f4f4;
$color-table: #efeeee;


// FONTS
$font-base: 'Roboto', sans-serif;
$font-main: 'Roboto', sans-serif;
$font-semibold: 'TradeGothic-BoldCondTwenty', sans-serif;
$font-icon: Times, sans-serif;
$font-size: 16px;
$line-height: 24px;
$font-weight: normal;
$text-align: left;

@media (max-width: 1023px) {
    $font-size: 16px;
}

// TRANSITION
$transition: 300ms;
$transition-in: 200ms;
$transition-out: 200ms;

// TEXT SELECTION
$selection-bg: $color-lightBlue;
$selection-color: inherit;

// OVERLAY
$overlay-bg: rgba(#1d1d1b, 0.55);
