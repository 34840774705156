section.resource-navigation {
    padding: 20px 0 40px;

    @media (max-width: 767px) {
        padding: 28px 0 24px;
    }

    &.article {
        padding: 20px 0 20px;
    }

    .resource-title {
        width: 40%;
        float: left;

        @media (max-width: 1023px) {
            width: 100%;
            float: left;
            text-align: center;
        }

        h2 {
            line-height: 62px;
            margin-bottom: 0;

            @media (max-width: 767px) {
                line-height: 32px;
                margin-bottom: 18px;
            }
        }
    }

    .resource-nav {
        width: 60%;
        float: left;
        text-align: right;

        @media (max-width: 1023px) {
            width: 100%;
            float: left;
            text-align: center;
        }

        a {
            text-transform: uppercase;
            line-height: 62px;
            margin-left: 50px;
            font-family: $font-semibold;
            font-size: 18px;
            color: $color-social-grey;
            position: relative;
            transition: all $transition-out ease-out;

            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 24px;
                margin-left: 24px;
            }

            @media (max-width: 480px) {
                font-size: 14px;
                margin-left: 15px;
            }

            &:after {
                content: '';
                background-color: transparent;
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: -6px;
                left: 0;
            }

            &.active {
                color: $color-body;

                &:after {
                    background-color: $color-red;
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                &:after {
                    background-color: $color-red;
                    transition: all $transition-in ease;
                }
            }
        }
    }

    .featured {
        width: 100%;
        float: left;
        margin-top: 20px;

        @media (max-width: 767px) {
            margin-top: 30px;
        }

        .image-container {
            width: 45%;
            float: left;

            @media (max-width: 767px) {
                width: 100%;
                float: left;
                padding-top: 50%;
            }
        }

        .text-container {
            width: 55%;
            float: left;
            padding: 40px 40px 40px 60px;
            text-align: left;
            background-color: #fff;

            @media (max-width: 767px) {
                width: 100%;
                float: left;
                padding: 20px 20px 30px;
            }

            label {
                display: block;
            }

            span.title {
                font-family: $font-base;
                font-size: 28px;
                font-weight: 700;
                line-height: 36px;
                margin-bottom: 20px;
                text-transform: none;
                width: 100%;
                float: left;

                @media (max-width: 767px) {
                    font-size: 18px;
                    line-height: 25px;
                    margin-bottom: 10px;
                }
            }

            span.excerpt {
                font-family: $font-base;
                color: $color-social-grey;
                font-size: 14px;
                line-height: 20px;
                width: 100%;
                float: left;

                p {
                    font-family: $font-base;
                    color: $color-social-grey;
                    font-size: 14px;
                    line-height: 20px;
                    width: 100%;
                    float: left;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

section.listing-filter {
    padding: 40px 0 10px;

    @media (max-width: 767px) {
        padding: 30px 0 15px;
    }

    &.resources-listing-filter {
        padding: 50px 0 0;

        @media (max-width: 767px) {
            padding: 30px 0 0;
        }
    }

    .container {
        .title-container {
            float: left;

            h2 {
                font-size: 32px;
                line-height: 42px;
                margin-bottom: 0;

                @media (max-width: 767px) {
                    font-size: 22px;
                    line-height: 42px;
                }
            }
        }

        .filter-button-container {
            float: right;
            border: 1px solid $color-grey;
            height: 40px;
            line-height: 42px;
            font-family: $font-semibold;
            font-size: 15px;
            text-transform: uppercase;
            padding: 0 13px;
            cursor: pointer;
            transition: all $transition-out ease-out;

            .filter-icon {
                position: relative;
                top: 2px;
                margin-right: 15px;
            }

            &:hover {
                color: $color-red-hover;
    			border: 1px solid $color-red-hover;
    			transition: all $transition-in ease-in;
            }

            &.active {
                transition: all $transition-in ease-in;
                border: 1px solid $color-red;
                background-color: $color-red;
                color: #fff;
            }

            &.resources-button-container {
                border: none;
                padding: 0;
            }
        }

        .filter-hide {
            height: 0;
            overflow: hidden;
            float: left;
            width: 100%;
            visibility: hidden;
            opacity: 0;
            transition: all $transition-out ease-out;

            &.active {
                height: 120px;
                transition: all $transition-in ease;
                visibility: visible;
                overflow: visible;
                opacity: 1;

                @media (max-width: 767px) {
                    height: 100%;
                }
            }
        }

        .filter-container {
            width: 100%;
            float: left;
            border: 1px solid $color-borderGrey;
            padding: 25px;
            margin-top: 20px;

            select {
                width: 25%;
                float: left;
                border-radius: 0;
                padding: 0 10px;
                margin-bottom: 3px;
            }

            span.select2 {
                width: calc(25% - 9px)!important;
                margin-right: 12px;
                float: left;
            }

            input[type=submit] {
                height: 54px;
                line-height: 56px!important;
                width: calc(25% - 9px)!important;
                float: right;
                cursor: pointer;
            }

            .select2-container--default .select2-selection--single {
                border: 1px solid $color-borderGrey;
                border-radius: 0px;
                box-shadow: none;
                background-color: #fff;
                line-height: 54px;
                height: 56px;

                .select2-selection__rendered {
                    line-height: 54px;
                    color: $color-body;
                    font-family: $font-main;
                    text-transform: none;
                    font-size: 16px;
                    padding-left: 20px;
                }

                .select2-selection__arrow {
                    height: 54px;

                    b {
                        border: none;
                        content:'\0041';
                        font-family: 'mates';
                        width: 20px;
                        height: 20px;
                        margin-left: 0;
                        margin-right: 0;
                        left: -10px;
                        top: 2px;
                        color: $color-social-grey;
                        // @include transition(all,200ms,ease);

                        &:after {
                            content:'\0041';
                            font-family: 'mates';
                            color: $color-social-grey;
                            font-size: 12px;
                            font-weight: normal;
                        }
                    }
                }
            }

            @media (max-width: 767px) {
                padding: 18px 16px;
                margin-top: 10px;

                select {
                    width: 100%;
                }

                span.select2 {
                    width: 100%!important;
                    margin-right: 0px;
                    margin-bottom: 10px;
                }

                input[type=submit] {
                    height: 48px;
                    line-height: 48px!important;
                    width: 100%!important;
                    float: left;
                    cursor: pointer;
                }

                .select2-container--default .select2-selection--single {
                    line-height: 46px;
                    height: 48px;

                    .select2-selection__rendered {
                        line-height: 46px;
                        font-size: 16px;
                        padding-left: 17px;
                    }

                    .select2-selection__arrow {
                        height: 48px;

                        b {
                            top: 2px;

                            &:after {
                            }
                        }
                    }
                }
            }
        }
    }
}

.select2-container {
    .select2-dropdown {
        background-color: #fff;
        border: none;
        border-radius: 0;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.17);

        .select2-results {
            .select2-results__options {
                position: relative;
                padding: 12px;
                // border-top: 4px solid $color-red;
            }
            .select2-results__option[aria-selected] {
                color: $color-dark;
                cursor: pointer;
                text-align: center;
                font-family: $font-base;
                text-align: left;
                font-size: 16px;
                line-height: 24px;
                text-transform: none;
            }

            .select2-results__option[aria-selected="true"] {
                background-color: $color-lightGrey;
            }

            .select2-results__option--highlighted[aria-selected] {
                background-color: $color-lightGrey;
            }
        }
    }

    .select2-search--dropdown {
        display: none;
    }
}

section.listing.column-block {
    padding: 0 0 40px;
    margin-top: 10px;

    @media (max-width: 767px) {
        padding-bottom: 20px;
    }

    &.resource-research-listing,
    &.resource-news-listing,
    &.resource-events-listing {
        margin-top: 0;
        margin-bottom: 20px;

        @media (max-width: 767px) {
            margin-bottom: 0px;
        }
    }

    .container {
        .filter-content.column-container.three {
            a span.column {
                margin-bottom: 30px;

                @media (max-width: 767px) {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 20px;
                }
            }

            a:last-child span.column {
                margin-right: 0;
            }

            a:nth-child(3n) span.column {
                margin-right: 0;
            }
            a:nth-child(3n+1) span.column {
                margin-left: 0;
            }


        }

        .button-container {
            width: 100%;
            float: left;
            text-align: center;
            margin-top: 10px;
        }
    }

    &.research-listing {
        .container {
            .filter-content.column-container.three {
                a span.column {
                    .image-container{
                        text-align: center;
                        padding: 30px;

                        img {
                            display: inline-block;
                            float: none;
                            margin: 0 auto;
                        }
                    }

                    .text-container {
                        h4 {
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 28px;
                        }

                        p {
                            color: #646464;
                        }
                    }

                }
            }
        }

    }
}

.research-listing--horizontal {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    > a {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        margin: 8px 0;
        width: 100%;

        > .row {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            width: 100%;

            @media (max-width: 767px) {
                display: block;
            }

            h4, p {
                margin: 0;
            }

            p {
                font-style: italic;
            }

            .image-container {
                align-items: center;
                display: flex;
                flex-shrink: 0;
                justify-content: center;
                padding: 16px;
            }

            .text-container {
                padding: 16px 16px 16px 0;

                @media (max-width: 767px) {
                    padding: 16px 16px 32px;
                    float: left;
                }

                .flag {
                    font-size: 12px;
                }

                .learn-more-arrow {
                    position: relative;
                    left: 3px;
                    top: 3px;
                }
            }
        }
    }

    &.playlists-listing {
        .row {
            padding: 16px;

            @media (max-width: 767px) {
                padding: 0px;
            }

            .image-container {
                float: left;
                width: 33.3%;
                position: relative;
                padding-top: 19%;
                margin-right: 16px;

                @media (max-width: 767px) {
                    width: 100%;
                    padding-top: 56.25%;
                    margin-right: 0px;
                }

                .bg-container {
                    position: absolute;
            		top: 0;
            		left: 0;
            		width: 100%;
            		height: 100%;
                    overflow: hidden;

                    img {
                        display: block;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        margin: 0px;
                    }

                    .play-icon {
                        position: absolute;
                		top: 0;
                		left: 0;
                		width: 100%;
                		height: 100%;
                        background-image: url('/assets/images/play-circle.svg');
                        background-size: 80px 80px;
                        background-repeat: no-repeat;
                        background-position: center;
                        transition: all $transition-out ease-out;
                    }
                }

                &:hover {
                    .bg-container {
                        .play-icon {
                            background-size: 85px 85px;
                        }
                    }
                }
            }
        }
    }
}
