section.search-filter {
    padding: 40px 0 45px;

    @media (max-width: 767px) {
        padding: 32px 0 36px;
    }

    .container {
        form {
            @media (max-width: 767px) {
                text-align: center;
            }

            input[type=search] {
                width: 500px;
                height: 40px;
                border: 1px solid $color-borderGrey;
                border-radius: 2px;
                box-shadow: none;
                background-color: #fff;
                padding-left: 16px;
                line-height: 40px;

                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 12px;
                }
            }

            .search-button-container {
                position: relative;

                @media (max-width: 767px) {
                    display: inline-block;
                    margin: 0 auto;
                }


                input[type=submit] {
                    text-indent: 15px;
                    cursor: pointer;
                }

                .magnifying {
                    position: absolute;
                    top: 0px;
                    left: 12px;
                    color: #fff;

                    @media (max-width: 767px) {
                        line-height: 42px;
                        top: 1px;
                    }
                }

            }
        }
    }
}
section.search-results {
    padding: 30px 0;

    .container {
        p.results {
            margin-bottom: 30px;
        }
        .search-inner {
            .results-filter-container {
                float: left;
                width: 300px;
                min-height: 100px;

                @media (max-width: 1023px) {
                    width: 150px;
                }

                @media (max-width: 767px) {
                    width: 100%;
                }

                ul {
                    width: 100%;
                    float: left;
                    list-style: none;

                    li {
                        width: 100%;
                        float: left;
                        list-style: none;

                        &:first-child {
                            display: none;
                        }

                        a.resultFilter {
                            font-family: $font-semibold;
                            text-transform: uppercase;
                            display: block;
                            font-size: 16px;
                            line-height: 40px;

                            &.active {
                                color: $color-red;
                            }
                        }
                    }
                }

                @media (max-width: 767px) {
                    position: relative;
                    width: 100%;
                    list-style: none;
                    min-height: 0;
                    height: 40px;
                    padding: 0;
                    margin: 0;
                    text-align: left;
                    border: 1px solid $color-lightGrey;
                    margin-left: 0px;
                    margin-bottom: 20px;

                    ul {
                        position: absolute;
                        width: 100%;
                        height: auto;
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        text-align: left;
                        top: 0;
                        left: 0;
                        background-color: #fff;
                        z-index: 1;
                        border: 1px solid $color-lightGrey;
                        transition: all $transition-out ease-out;

                        &.active {
                            z-index: 2;
                        }

                        li {
                            position: relative;
                            display: inline-block;
                            font-family: $font-semibold;
                            font-size: 16px;
                            color: $color-dark;
                            display: none;
                            text-align: left;
                            margin: 0;
                            padding: 0px 10px;
                            text-transform: uppercase;

                            &:first-child {
                                display: block !important;

                                &:after {
                                    content: '\0041';
                                    font-family: 'mates';
                                    color: #646464;
                                    font-size: 12px;
                                    font-weight: normal;
                                    position: absolute;
                                    line-height: 40px;
                                    top: -1px;
                                    right: 10px;
                                }

                                a:hover {
                                    color: $color-dark;
                                }
                            }

                            &.hide {
                                display: none!important;
                            }

                            &.active {
                                display: block;
                                z-index: 2;
                            }

                            a {
                                font-family: $font-semibold;
                                font-size: 16px;
                                line-height: 42px;
                                cursor: pointer;


                                &:hover {
                                    color: $color-red;
                                }
                            }
                        }
                    }
                }
            }

            .results-container {
                width: calc(100% - 300px);
                float: left;

                @media (max-width: 1023px) {
                    width: calc(100% - 150px);
                }

                @media (max-width: 767px) {
                    width: 100%;
                }

                ul {
                    width: 100%;
                    float: left;
                    list-style: none;
                    border-top: 2px solid $color-borderGrey;

                    li {
                        padding: 25px 0;
                        width: 100%;
                        float: left;
                        border-bottom: 2px solid $color-borderGrey;

                        span.title {
                            font-family: $font-base;
                            font-weight: 500;
                            float: left;
                            width: 100%;
                            font-size: 18px;
                            line-height: 26px;
                            color: $color-dark;

                            @media (max-width: 767px) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        a.link {
                            width: 100%;
                            float: left;
                            font-size: 14px;
                            line-height: 20px;
                            font-family: $font-base;
                            font-size: 14px;
                            margin-bottom: 20px;
                            word-wrap: break-word;

                            @media (max-width: 767px) {
                                margin-bottom: 10px;
                            }

                            &:visited {
                                color: $color-link-resting;
                            }

                            i {
                                margin-left: 10px;
                                line-height: 20px;
                            }
                        }

                        span.description {
                            width: 100%;
                            float: left;
                            font-family: $font-base;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;

                            p {
                                width: 100%;
                                float: left;
                                font-family: $font-base;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }

            .search-top {
                width: 100%;
                float: left;
                padding-bottom: 20px;

                .left {
                    float: left;
                    width: 40%;
                    text-align: left;

                    @media (max-width: 767px) {
                        width: 100%;
                        text-align: center;

                        p {
                            margin-bottom: 10px;
                        }
                    }
                }

                .right {
                    float: left;
                    width: 60%;
                    text-align: right;

                    .order-by,
                    .sort-by-dropdown {
                        display: inline-block;
                        line-height: 40px;
                    }

                    .order-by {
                        position:relative;
                        top: -13px;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        text-align: center;
                    }

                    .sort-by-dropdown {
                        position: relative;
                        width: 150px;
                        list-style: none;
                        height: 40px;
                        padding: 0;
                        margin: 0;
                        text-align: left;
                        border: 1px solid $color-lightGrey;
                        margin-left: 10px;

                        ul {
                            position: absolute;
                            width: 100%;
                            height: auto;
                            list-style: none;
                            padding: 0;
                            margin: 0;
                            text-align: left;
                            top: 0;
                            left: 0;
                            background-color: #fff;
                            z-index: 1;
                            border: 1px solid $color-lightGrey;
                            transition: all $transition-out ease-out;

                            &.active {
                                z-index: 2;
                            }

                            li {
                                position: relative;
                                display: inline-block;
                                font-family: $font-semibold;
                                font-size: 16px;
                                color: $color-dark;
                                display: none;
                                text-align: left;
                                margin: 0;
                                padding: 0px 10px;
                                text-transform: uppercase;

                                &:first-child {
                                    display: block !important;

                                    &:after {
                                        content: '\0041';
                                        font-family: 'mates';
                                        color: #646464;
                                        font-size: 12px;
                                        font-weight: normal;
                                        position: absolute;
                                        line-height: 40px;
                                        top: -1px;
                                        right: 10px;
                                    }

                                    a:hover {
                                        color: $color-dark;
                                    }
                                }

                                &.hide {
                                    display: none!important;
                                }

                                &.active {
                                    display: block;
                                }

                                a {
                                    font-family: $font-semibold;
                                    font-size: 16px;
                                    line-height: 42px;
                                    cursor: pointer;


                                    &:hover {
                                        color: $color-dark;
                                    }

                                    &.active {
                                        color: $color-dark;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
