footer {
    width: 100%;
    float: left;
    padding: 50px 0;
    border-top: 2px solid $color-lightGrey;

    @media (max-width: 767px) {
        padding: 40px 0 20px;
    }

    .container {
        a.site-logo {
            float: left;
            margin-right: 15px;

            @media (max-width: 767px) {
                display: none;
            }
        }

        .site-title {
            float: left;
            padding-top: 10px;

            @media (max-width: 767px) {
                display: none;
            }

            p {
                font-family: $font-semibold;
                color: $color-red;
                font-size: 28px;
                text-transform: uppercase;
                line-height: 24px;
                margin-bottom: 0;
                letter-spacing: 0.4px;
            }
        }

        .footer-right {
            float: right;

            @media (max-width: 767px) {
                width: 100%;
                float: left;
                text-align: center;
            }

            span {
                font-size: 24px;
                font-family: $font-semibold;
                line-height: 42px;
                color: $color-dark;
                text-transform: uppercase;
                display: inline-block;
                margin-right: 10px;
            }

            i {
                width: 40px;
                height: 40px;
                background-color: $color-red;
                border-radius: 50%;
                display: inline-block;
                text-align: center;

                &:before {
                    color: #fff;
                    font-size: 18px;
                    line-height: 42px;
                }
            }

            a.footer-contact {
                font-size: 24px;
                font-family: $font-semibold;
                line-height: 42px;
                color: $color-red;
                display: inline-block;
                margin-left: 10px;
            }
        }

        .footer-nav {
            width: 100%;
            float: left;
            padding-top: 50px;

            @media (max-width: 767px) {
                padding-top: 30px;
            }

            .footer-columns {
                width: 16.6666%;
                float: left;
                padding-right: 20px;
                text-align: left;

                @media (max-width: 1023px) {
                    width: 33.3333%;
                    margin-bottom: 20px;
                }

                span.column-title {
                    font-family: $font-semibold;
                    font-size: 18px;
                    line-height: 1.3;
                    color: $color-red;
                    text-transform: uppercase;
                }

                a {
                    display: block;
                    font-size: 14px;
                    font-family: $font-main;
                    font-weight: normal;
                    line-height: 2.3;
                    color: $color-dark;
                    letter-spacing: normal;
                }

                @media (max-width: 767px) {
                    width: 100%;
                    padding: 0;
                    margin: 0;

                    .column-title {
                        float: left;
                        width: 100%;
                        text-align: left;
                        position: relative;
                        line-height: 25px;
                        position: relative;
                        cursor: pointer;
                        margin-bottom: 20px;

                        &:after {
                            content: '+';
                            font-family: $font-base;
                            font-size: 20px;
                            position: absolute;
                            right: 0;
                            top: -1px;
                            line-height: 25px;
                            color: $color-dark;
                        }

                        &.active {
                            &:after {
                                content: '_';
                                font-size: 22px;
                                top: -10px;
                            }
                        }
                    }

                    .column-container {
                        width: 100%;
                        float: left;
                        padding-bottom: 25px;
                        display: none;
                    }
                }
            }
        }
    }
}

.footer-bottom {
    float: left;
    width: 100%;
    background-color: $color-lightGrey;
    padding: 25px 0;

    .icon-column {
        width: 100px;
        text-align: left;
        float:left;

        .charity-logo {
            float: left;
        }
    }

    .text-column {
        width: calc(100% - 250px);
        float: left;
        padding-right: 10%;
        padding-bottom: 30px;
        position: relative;

        .bottom-text {
            float: left;
            font-family: $font-main;
            font-size: 12px;
            line-height: 14px;
            color: $color-dark;

            @media (max-width: 767px) {
                font-size: 10px;
            }
        }

        .bottom-links {
            float: left;
            font-family: $font-main;
            font-size: 10px;
            line-height: 1.8;
            color: $color-dark;
            position: absolute;
            bottom: 0;
            left: 0;

            a {
                margin-left: 10px;
                text-decoration: underline;

                &:hover {
                    transition: all $transition-in ease-in;
                    color: $color-red-hover;
                }
            }
        }

        @media (max-width: 767px) {
            width: 100%;
            float: left;
            padding-right: 0;
            padding-bottom: 10px;
            padding-top: 10px;

            .bottom-links {
                position:relative;
                bottom: auto;
                left: auto;
                float: left;
                width: 100%;
                margin-top: 10px;
                line-height: 1.4;
            }

        }
    }

    .socials-column {
        width: 150px;
        float: left;
        text-align: right;
        position: relative;

        &.mobile {
            display:none;
        }

        @media (max-width: 767px) {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: block;
                float: right;
                padding-top: 15px;

                .weRD {
                    display: none;
                }
            }
        }

        .socials {
            a.social {
                float: right;
                margin-left: 30px;
                color: $color-social-grey;
                font-size: 20px;

                &:hover {
                    transition: all $transition-in ease-in;
                    color: $color-red-hover;
                }
            }
        }

        .weRD {
            font-family: Helvetica;
            font-size: 10px;
            line-height: 16px;
            position: absolute;
            bottom: 0;
            right: 0;
            text-align: right;
        }
    }

    .weRD.mobile {
        display: none;
        font-family: Helvetica;
        font-size: 10px;
        line-height: 16px;
        float: left;
        width: 100%;
        text-align: left;

        @media (max-width: 767px) {
            display: block;
        }
    }
}

html.no-touch {
    .footer-nav {
        a:hover {
            text-decoration: underline;
        }
    }
}
