.navbar {
    width: 100%;
    float: left;
    text-align: left;

    #navbarNavDropdown {
        width: 100%;
        float: left;
        text-align:center;

        ul {
            width: 100%;
            float: left;

            li {
                float: left;
                width: auto;
                height: 40px;
                margin-right: 30px;

                a {
                    color: #fff;
                    font-family: $font-semibold;
                    font-size: 17px;
                    text-transform: uppercase;
                    line-height: 44px;
                    letter-spacing: 0.3px;
                    padding: 0 5px;
                    position: relative;
                    display: inline-block;
                    transition: all $transition-in ease-in-out;

                    @media (max-width: 767px) {
                        padding: 0 9px;
                    }

                    &:after {
                        content: '';
                        background-color: transparent;
                        height: 4px;
                        width: 100%;
                        position: absolute;
                        bottom: 4px;
                        left: 0;
                    }
                }

                &.dropdown {
                    position: relative;
                }

                div.dropdown-menu {
                    // display: none;
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    width: 280px;
                    left: 0;
                    top: 40px;
                    background-color: #fff;
                    padding: 20px 8px;
                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
                    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
                    -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
                    transition: all $transition-in ease-in-out;


                    .mobile-back-button {
                        display: none;
                    }

                    .container {
                        padding: 0;
                    }

                    &.active {
                        visibility: visible;
                        opacity: 1;
                    }

                    div.nav-inner {
                        width: 100%;
                        position: relative;

                        .mobile-title {
                            display: none;
                        }

                        a.dropdown-item {
                            float: left;
                            width: 100%;
                            text-align: left;
                            color: $color-dark;
                            font-size: 18px;
                            border: none;
                            padding: 0 12px;
                            line-height: 40px;

                            &:after {
                                border: none;
                                display: none;
                            }

                            &:hover {
                                background-color: $color-lightGrey;
                            }
                        }
                    }

                    @media (max-width: 1023px) {
                        display: block;
                        position: fixed;
                        visibility: visible;
                        opacity: 1;
                        float: left;
                        height: calc(100% - 57px);
                        width: 100%;
                        background-color: #fff;
                        top: 57px;
                        left: -100vw;
                        z-index: 9999;
                        overflow-y: scroll;
                        transition: all $transition-out ease-in-out;
                        padding: 0px;
                        box-shadow: none;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;


                        &.active {
                            transform: translateX(100vw);
                            transition: all $transition-in ease;
                        }

                        .mobile-back-button {
                            display: block;
                            float: left;
                            width: 100%;
                            height: 57px;
                            overflow: hidden;
                            text-align: left;
                            padding-left: 24px;
                            line-height: 57px;
                            font-family: $font-semibold;
                            border-bottom: $color-grey;
                            text-transform: uppercase;

                            i:before {
                                transform: rotate(180deg);
                                -webkit-transform: rotate(180deg);
                                -moz-transform: rotate(180deg);
                                -o-transform: rotate(180deg);
                                margin-right: 7px;
                                position: relative;
                                top: 2px;
                            }
                        }

                        .container {
                            .nav-inner {
                                width: 100%;
                                float: left;

                                .mobile-title {
                                    display: block;
                                    height: 57px;
                                    overflow: hidden;
                                    text-align: left;
                                    padding-left: 24px;
                                    line-height: 57px;
                                    font-family: $font-semibold;
                                    color: $color-red;
                                    text-transform: uppercase;
                                }

                                a.dropdown-item {
                                    padding-left: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

html.no-touch {
    .navbar {
        #navbarNavDropdown {
            ul {
                li {
                    a {
                        &:hover {
                            &:after {
                                background-color: $color-red;
                                transition: all $transition-out ease-out;
                            }
                        }

                        &.hover {
                            &:after {
                                background-color: $color-red;
                                transition: all $transition-out ease-out;
                            }
                        }
                    }
                }
            }
        }
    }
}
