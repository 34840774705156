section.campaign-banner.image-text {
    margin: 0px!important;
    margin-top: -40px!important;

    @media (max-width: 767px) {
        margin: 0 0 15px!important;
    }

    .half-container {
        width: 50%;
        float:left;

        &.right {
            float: right;
        }

        @media (max-width: 767px) {
            width: 100%;
            float: left;
        }

        .text-container {
            max-width: 635px;

            &.left {
                float: right;
                padding: 0 15% 0 20px;

                @media (max-width: 1023px) {
                    padding: 0 20px;
                }
            }

            @media (max-width: 767px) {
                max-width: 100%;
                width: 100%;

                &.left {
                    float: left;
                    padding: 36px 40px 40px;
                    text-align: center;
                }

                .text-column {
                    .v-wrap {
                        .v-box.left-align {
                            text-align: center;
                        }
                    }
                }
            }
        }

        .image-container {
            height: 400px;

            @media (max-width: 767px) {
                height: 200px;
            }
        }
    }

    &.partners-banner-block {
        .half-container {
            .text-container {
                width: 100%;
            }

            .image-container {
                height: 440px;

                @media (max-width: 767px) {
                    height: 200px;
                }
            }
        }
    }
}

section.campaign-intro.intro-heading {
    padding: 85px 0 20px;

    @media (max-width: 767px) {
        padding: 40px 0 5px;
    }
}
