* {
	margin: 0;
	padding: 0;
}

*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

main {
	// @include transition(all,100ms,ease);
	// opacity: 0;
	//
	// &.loaded {
	// 	opacity: 1;
	// }
}

section {
	width: 100%;
	float: left;
}

html {
	font-family: $font-base;
	overflow: auto !important;
}

body,
html {
	font-family: $font-base;
	width: 100%;
	min-height: 100%;
	height:100%;
	background:#fff;
	float: left;
	//overflow-x: hidden;
}

body {
	text-align: $text-align;
	position: relative;
	/*overflow-x: hidden;*/

	div.container {
		max-width: $container-max;
		margin: 0 auto;
		padding: 0 $container-padding;

		@media (max-width: 767px) {
			padding: 0 24px;
		}
	}

	div.inner {
		max-width: $container-inner-max;
		margin: 0 auto;
	}

	div.inner-medium {
		max-width: $container-inner-medium-max;
		margin: 0 auto;
	}
}

body {
    animation: fadeInTransition 300ms;
}

section {
	width: 100%;
	float: left;
}

ul {
	list-style: none;
}
ol {

	font-family: $font-main;
	li {
		padding-left: 20px;
	}
}

@keyframe fadeInTransition {
    from {opacity: 0;}
    to {opacity: 1;}
}

@-webkit-keyframes fadeInTransition {
    from {opacity: 0;}
    to {opacity: 1;}
}

main {
	overflow-x: hidden;
	// min-height: 100%;
	margin-bottom: 0px;
	padding-top:120px;
	background:#fff;
	float:left;
	width: 100%;

	@media (max-width: 1023px) {
		padding-top: 62px;
    }

}

article,
footer,
header,
section {
	position: relative;
	margin: 0 auto;
	//width: 100%;
}

img {
	display: block;
	vertical-align: middle;
	max-width: 100%;
	border: none;
}

a {
	border: none;
	cursor: pointer;
	text-decoration: none;
	-webkit-appearance: none;
	border-radius: 0px;
	-webkit-border-radius:0px;


	&:focus,
	&:hover,
	&:active {
		outline: 0;
	}
}

input {
	-webkit-appearance: none;
	border-radius: 0px;
	-webkit-border-radius:0px;
}

[class*="icon-"] {
	&:before {
		font-family: $font-icon;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-decoration: none;
		text-transform: none;
	}

	span {
		display: none;
	}
}

// span.buttonContainer,
// span.submitContainer {
// 	width: auto!important;
// 	display: inline-block!important;
// 	margin-bottom: 0!important;
// 	padding: 0!important;
// 	overflow: hidden!important;
// 	height: 60px!important;
// 	border-radius: 6px;
//
// 	&.ctaButtonContainer {
// 		height: 40px!important;
// 		border-radius: 6px!important;
// 		overflow: hidden;
// 	}
// }

a.btn,
input.btn,
button.btn {
	background-color: $color-red;
	font-family: $font-semibold;
	font-weight: 600;
	font-size: 18px;
	display: inline-block;
	line-height: 60px!important;
	padding: 0 25px;
	text-align: center;
	color: #f0fafd;
	border-radius: 6px;
	overflow: hidden;
	-moz-transition: all 0.6s ease-in-out;
	-o-transition: all 0.6s ease-in-out;
	-webkit-transition: all 0.6s  ease-in-out;
	transition: all 0.6s  ease-in-out;
	border: none;
	cursor: pointer;

	&.ctaButton {
		line-height: 40px!important;
		font-size: 16px;
	}
}

html.no-touch {
	a.btn,
	input.btn,
	button.btn {
		&:hover {
			transform: scale(1.07);
			-webkit-transform: scale(1.07);
			-moz-transform: scale(1.07);
			-o-transform: scale(1.07);
		}
	}
}

.mfp-iframe-holder .mfp-content {
	max-width: 70vw!important;
}

/* Placeholder text color -- selectors need to be separate to work. */
::-webkit-input-placeholder {
	color: $color-dark;
	opacity: 0.5;
}

:-moz-placeholder {
	color: $color-dark;
	opacity: 0.5;
}

::-moz-placeholder {
	color: $color-dark;
	opacity: 0.5;
}

:-ms-input-placeholder {
	color: $color-dark;
	opacity: 0.5;
}

$aos-distance: 40px !default;

body.notFound {
	width: 100%;
	float: left;
	height: 100vh!important;

	main {
		float: left;
		width: 100%;
		min-height: 0;

		section.notFoundContainer {
			height: 100%;
			max-height: calc(100vh - 411px);

			.container {
				.inner {
					.v-wrap {
						.v-box {
							width: 100%;
							text-align: center;

							h2 {
								margin-bottom: 15px;
							}

							h3 {
								margin-bottom: 30px;
							}
						}
					}
				}
			}
		}
	}
}

section.not-found {
	padding: 80px 0px;

	.container {
		.inner {
			.v-wrap {
				.v-box {
					width: 100%;
				}
			}
		}
	}
}
