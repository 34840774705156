html,
body,
h2,
h3,
h4,
h5,
h6,
p,
ul,
button,
input,
select,
textarea,
label {
	font-family: $font-main;
	font-size: $font-size;
	font-weight: $font-weight;
	color: $color-body;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 20px;
	color: $color-body;
}

h1 {
	font-size: 64px;
	line-height: 60px;
	font-family: $font-semibold;
	color: $color-dark;
	font-weight: bold;
	text-transform: uppercase;
	position: relative;
	letter-spacing: normal;

	@media (max-width: 767px) {
		font-size: 44px;
		line-height: 40px;
	}
}

h2 {
	font-size: 40px;
	line-height: 1;
	font-family: $font-semibold;
	color: $color-dark;
	font-weight:bold;
	position: relative;
	letter-spacing: normal;
	text-transform: uppercase;
	margin-bottom: 15px;

	@media (max-width: 767px) {
		font-size: 28px;
		line-height: 32px;
	}
}

h3 {
	font-size: 24px;
	font-weight: bold;
	line-height: 20px;
	font-family: $font-semibold;
	color: $color-dark;

	@media (max-width: 767px) {
		font-size: 18px;
		line-height: 24px;
	}
}

h4 {
	font-size: 20px;
	font-weight: 700;
	line-height: 28px;

	@media (max-width: 767px) {
		font-size: 16px;
		line-height: 24px;
	}
}

h5 {
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
}

h6 {
	font-size: 12px;
	line-height: 20px;
	font-weight: 400;
}

p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 15px;

	strong {
		font-weight: 600;
	}

	em {
		font-style: italic;
	}
}

a,
input[type=submit],
button[type=submit] {
	color: $color-body;
	text-decoration: none;
	transition: all $transition-out ease-in-out;

	&:hover,
	&.active {

	}

	&.button {
		background-color: transparent;
		color: $color-dark;
		display: inline-block;
		padding: 0 32px;
		line-height: 56px !important;
		height: 54px;
		border: 1px solid $color-buttonGrey;
		font-family: $font-semibold;
		font-size: 19px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		width: auto;
		margin: 0 auto;
		border-radius: 0;

		@media (max-width: 767px) {
			padding: 0 24px;
		}

		&:hover {
			background-color: $color-buttonGrey;
			// transition: all $transition-in ease-in;
		}

		&.alt {
			background-color: $color-red;
			border: 1px solid $color-red;
			color: #fff;

			&:hover {
				background-color: $color-red-hover;
				border: 1px solid $color-red-hover;
			}
		}


		&.header-button,
		&.standard-button {
			line-height: 42px !important;
			height: 40px;
			font-size: 17px;
			letter-spacing: 0.3px;
			padding: 0 24px;

			&.phone-button {
				margin-left: 4px;
			}

			&.wide {
				width: calc(33.333% - 20px);
				text-align: center;

				@media (max-width: 767px) {
					width: 100%;
				}
			}

			&.centered {
				margin: 0 auto;
				display: inline-block;
				float: none!important;
			}
		}

		&.large {
			line-height: 66px !important;
			height: 64px;
			padding: 0 40px;
			font-size: 22px;

			&.large-text {
				font-size: 32px;

				i:before {
					font-size: 22px !important;
					margin-left: 0 !important;
					margin-right: 16px;
				}
			}
		}
	}
}

div.submit {
	input[type=submit],
	button[type=submit] {
		background-color: $color-red;
		border: 1px solid $color-red;
		color: #fff;
		display: inline-block;
		padding: 0 32px;
		line-height: 56px !important;
		height: 54px;
		font-family: $font-semibold;
		font-size: 19px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		width: auto;
		margin: 0 auto;
		border-radius: 0;

		@media (max-width: 767px) {
			padding: 0 24px;
		}

		&:hover {
			background-color: $color-red-hover;
			border: 1px solid $color-red-hover;
		}
	}
}



a.link {
	color: $color-link-resting;

	&:hover {
		color: $color-link-hover;
		// transition: all $transition-in ease-in;
	}

	&:visited {
		color: $color-link-visited;
	}
}

label {
	font-family: $font-semibold;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 24px;
	font-weight: bold;
	color: $color-red;
	margin-bottom: 15px;
	display: inline-block;

	&.large-label {
		font-size: 18px;
	}
}

blockquote {
	background-color: #f3f3f3;
	padding: 22px 27px;
	margin-bottom: 25px;

	p {
		margin: 0;
		color: $color-dark;
		font-weight: normal;

		strong {
			font-weight: 500;
		}
	}
}

[class*='mates-icon-']:before{
	display: inline-block;
   font-family: 'mates';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.mates-icon-chevron-down:before{content:'\0041';}
.mates-icon-clear:before{content:'\0042';}
.mates-icon-nav-close:before{content:'\0043';}
.mates-icon-nav-menu:before{content:'\0044';}
.mates-icon-nav-phone:before{content:'\0045';}
.mates-icon-nav-search:before{content:'\0046';}
.mates-icon-arrow-right:before{content:'\0047';}
.mates-icon-filter:before{content:'\0048';}
.mates-icon-pdf:before{content:'\0049';}
.mates-icon-print:before{content:'\004a';}

[class*='social-icon-']:before{
	display: inline-block;
   font-family: 'mates-social';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.social-icon-instagram:before{content:'\0041';}
.social-icon-facebook:before{content:'\0042';}
.social-icon-youtube:before{content:'\0043';}
.social-icon-linkedin:before{content:'\0044';}
.social-icon-twitter:before{content:'\0045';}
