div.fields {

    .field {
        width: 100%;
        float: left;
        padding: 0;
        margin-bottom: 24px;

        @media (max-width: 767px) {
            margin-bottom: 16px;
        }

        &.half-width {
            width: 50%;
            float: left;
            padding: 0 12px;
            margin-bottom: 24px;

            &.left {
                padding-left: 0;
            }

            &.right {
                padding-right: 0;
            }

            @media (max-width: 767px) {
                width: 100%;
                padding: 0;
                margin-bottom: 16px;
            }
        }

        &.full-width {
            width: 100%;
            float: left;
            padding: 0;
            margin-bottom: 24px;

            @media (max-width: 767px) {
                margin-bottom: 16px;
            }
        }
    }

    header {
        position: relative;
        float: left;
        width: 100%;
        z-index: 1;
        margin-top: 20px;
        height: auto;
    }

    div.heading {
        label {
            color: $color-dark;
            font-family: $font-base;
            font-size: 14px;
            line-height: 16px;
            text-transform: none;
            margin-bottom: 10px;
        }
    }

    input[type=text],
    input[type=email],
    textarea  {
        border: none;
        box-shadow: none;
        border: 1px solid $color-borderGrey;
        border-radius: 0px;
        box-shadow: none;
        background-color: #fff;
        padding-left: 20px;
        line-height: 54px;
        width: 100%;

        &:focus {
            border-color: $color-input-focus;
            background-color: $color-lightGrey;
        }
    }

    textarea  {
        line-height: 1.4;
        padding: 19px 20px;
        resize: none;
    }

    select {
        width: 100%;
        float: left;
        border-radius: 0;
    }

    span.select2 {
        width: 100%;
        float: left;
    }

    .select2-container--default .select2-selection--single,
    .select2-container--default .select2-selection--multiple {
        border: 1px solid $color-borderGrey;
        border-radius: 0px;
        box-shadow: none;
        background-color: #fff;
        line-height: 54px;
        height: 56px;

        .select2-selection__rendered {
            line-height: 54px;
            color: $color-body;
            font-family: $font-main;
            text-transform: none;
            font-size: 16px;
            padding-left: 20px;
        }

        .select2-selection__arrow {
            height: 54px;

            b {
                border: none;
                content:'\0041';
                font-family: 'mates';
                width: 20px;
                height: 20px;
                margin-left: 0;
                margin-right: 0;
                left: -10px;
                top: 0px;
                color: $color-social-grey;
                // @include transition(all,200ms,ease);

                &:after {
                    content:'\0041';
                    font-family: 'mates';
                    color: $color-social-grey;
                    font-size: 12px;
                    font-weight: normal;
                }
            }
        }
    }

    .select2-container--default .select2-selection--multiple {
        ul.select2-selection__rendered {
            height: 40px;
            line-height: 40px;
            margin-top: 7px;
        }
        .select2-selection__choice {
            border: none!important;
            margin-top: 0!important;
        }
        .select2-selection__choice__remove {
            margin-left: 4px;
            margin-right: 4px;
        }
    }
}
div.submit {
    width: 100%;
    text-align: center;
}

button[type="submit"] {
    display: inline-block;
    padding: 0 32px;
    line-height: 56px !important;
    height: 54px;
    border: 1px solid $color-grey;
    font-family: $font-semibold;
    font-size: 19px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    width: auto;
    margin: 0 auto;
    border-radius: 0;
    background-color: $color-red;
    border: 1px solid $color-red;
    color: #fff!important;
    cursor: pointer;
    margin-top: 16px;

    &:hover {
        background-color: $color-red-hover;
        border: 1px solid $color-red-hover;
    }
}

.field.checkboxes {

    .heading {
        display: block;
        color: #171717;
        font-family: "Roboto",sans-serif;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-transform: none;

        legend {
            display: block;
            color: #171717;
            font-family: "Roboto",sans-serif;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            text-transform: none;
            margin-bottom: 10px;
        }

        p {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 15px;
        }
    }
    [type="checkbox"]:checked,
	[type="checkbox"]:not(:checked) {
	    position: absolute;
	    left: -9999px;
	}

	label {
		position: relative;
	    padding-left: 28px !important;
	    cursor: pointer;
	    line-height: 24px !important;
	    float:left;
        width: 100%;
	    color: #171717;
		margin-left: 0;
		margin-top:5px !important;
        margin-bottom: 5px;
        text-transform: none;

		&:before {
			content: '';
		    position: absolute;
		    left: 0;
		    top: 0;
		    width: 20px;
		    height: 20px;
		    border: 1px solid #e1e1e1 ;
		    border-radius: 2px ;
		    background: #fff;
		}

		&:after {
		    opacity: 0;
		    -webkit-transform: scale(0);
		    transform: scale(0);
			content: '';
		    width: 8px;
		    height: 8px;
		    background: $color-red;
		    position: absolute;
		    top: 6px;
		    left: 6px;
		    border-radius: 100%;
		    -webkit-transition: all 0.2s ease;
		    transition: all 0.2s ease;
		}

        &.checked {
            &:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
        }
	}
}
