section.article {
    padding-top: 50px;
    padding-bottom: 25px;

    @media (max-width: 767px) {
        padding-top: 30px;

        &.generic-content {
            padding-top: 15px;
            padding-bottom: 0px;
        }
    }

    .container {
        .inner-medium {
            h1 {
                text-transform: none;
                font-size: 48px;
                font-family: $font-main;
                font-weight: bold;
                line-height: 1.17;
                margin-bottom: 40px;

                @media (max-width: 767px) {
                    font-size: 24px;
                    margin-bottom: 30px;
                }

                &.eventTitle {
                    margin-bottom: 10px;

                    @media (max-width: 767px) {
                        margin-bottom: 10px;
                    }
                }
            }

            h4.event-sub-title {
                font-weight: 400;
            }

            span.eventDetails {
                width: 100%;
                float: left;
                border: 1px solid #e7e7e7;
                padding: 20px 30px;

                @media (max-width: 767px) {
                    padding: 20px
                }


                .column {
                    width: 33.33%;
                    float: left;

                    h5 {
                        width: 100%;
                        float:left;
                        color: #646464;
                        margin-bottom: 5px;
                    }

                    p {
                        width: 100%;
                        float:left;
                        font-weight: 500;
                    }

                    .v-wrap {
                        .v-box {
                            width: 100%;
                        }
                    }

                    @media (max-width: 1023px) {
                        width: 50%;

                        &.columnThree {
                            width: 100%;
                            padding-top: 20px;
                        }
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }

            .article-info {
                width: 100%;
                float: left;
                padding-right: 100px;
                position: relative;

                @media (max-width: 767px) {
                    padding-right: 60px;
                }

                .published {
                    margin-bottom: 0;
                }

                .article-info-right {
                    position: absolute;
                    right: 0;
                    top: 0;
                    text-align: right;

                    a.pdf-button {
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        border-radius: 50%;
                        background-color: $color-borderGrey;
                        display: inline-block;
                        padding: 11px;
                        padding-left: 12px;
                        margin-left: 5px;
                    }

                    a.print {
                        @media (max-width: 767px) {
                            display: none;
                        }
                    }
                }
            }

            .image-container {
                float: left;
                width: calc(100% + 130px);
                margin: 50px -65px;
                padding-top: 56%;

                @media (max-width: 1023px) {
                    width: calc(100% + 48px);
                    margin: 30px -24px;
                }
            }
        }
    }
}

section.related-article.column-block {
    margin-bottom: 0!important;

    @media (max-width: 1023px) {
        padding: 40px 0;
    }

    h3.related-title {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .container {
        .column-container.three {
            .column {

            }

            a:first-child span.column {
                margin-left: 0;

                @media (max-width: 1023px) {
                    margin-left: 0;
                }

                @media (max-width: 767px) {
                    margin: 0;
                }
            }

            a:last-child span.column {
                margin-right: 0;

                @media (max-width: 1023px) {
                    margin-right: 0;
                }

                @media (max-width: 767px) {
                    margin: 0;
                }
            }
        }
    }
}
