header {
    @include transition(all,300ms,ease);
    width: 100%;
    height: 120px;
    position: fixed;
    z-index: 20;

    @media (max-width: 1023px) {
        height: 62px;
    }

    .header-top {
        background-color: #fff;
        height: 80px;
        padding: 10px 0;

        @media (max-width: 1023px) {
            height: 62px;
            border-bottom: 1px solid $color-lightGrey;
        }

        .container {
            @media (max-width: 767px) {
                padding: 0 12px 0 24px;
            }
        }

        .hamburger-wrapper {
            float:left;
            position: relative;
            z-index: 999;
            width: 24px;
            margin-right: 18px;
            opacity: 1;
            visibility: visible;
            display: none;
            left: 0px;

            @media (max-width: 1023px) {
                display: block;
            }

            a.nav {
                float: left;
                width: 24px;
                position: relative;
                text-align: center;
                display: inline-block;
                padding-top: 5px;

                i.hamburger {
                    font-size: 20px;
                }

                span.menu-title {
                    display: block;
                    font-size: 10px;
                    line-height: 10px;
                    letter-spacing: 0.2px;
                    text-transform: uppercase;
                    font-family: $font-semibold;
                }
            }
        }

        a.site-logo {
            float: left;
            margin-right: 10px;

            @media (max-width: 1023px) {
                width: 40px;
                padding-top: 2px;
            }
        }

        .site-title {
            float: left;
            padding-top: 10px;

            @media (max-width: 1023px) {
                padding-top: 5px;
            }

            p {
                font-family: $font-semibold;
                font-size: 24px;
                text-transform: uppercase;
                line-height: 22px;
                margin-bottom: 0;
                letter-spacing: 0.4px;
                transition: all $transition-out ease-in-out;

                @media (max-width: 1023px) {
                    font-size: 20px;
                    line-height: 19px;
                }
            }

            &:hover {
                p {
                    color: $color-darkgrey;
                }
            }

            &.mates {
                p {
                    line-height: 45px;

                    @media (max-width: 1023px) {
                        line-height: 38px;
                    }
                }
            }
        }

        .header-right {
            float: right;
            padding-top: 10px;
            padding-right: 80px;
            position: relative;

            @media (max-width: 1023px) {
                padding-top: 1px;
                padding-right: 70px;
            }

            a:first-child {
                margin-right: 5px;
            }

            a.alt {
                i {
                    &::before {
                        font-size: 14px;
                        margin-right: 2px;
                    }
                }
            }

            a.dbox-donation-button {
                @media (max-width: 767px) {
                    display: none;
                }
            }

            a.phone-button {
                @media (max-width: 767px) {
                    padding: 0 12px;
                    line-height: 40px!important;
                    border-radius: 2px;
                    width:40px;

                    i:before {
                        font-size: 16px;
                        position:relative;
                        top: 2px;
                    }

                    span.phone-text {
                        display: none;
                    }
                }
            }

            a.shop-link {
                position: absolute;
                right: 0px;
                top: 0px;
                z-index: 1;

                @media (max-width: 1023px) {
                    top: -2px;

                    img {
                        width: 50px!important;
                    }
                }
            }

        }
    }

    .header-bottom {
        background-color: rgba(23, 23, 23, 0.8);
        height: 40px;

        @media (max-width: 1023px) {
            display: none;
        }

        .container {
            position: relative;
            height: 100%;

            .navbar {
                position: relative;
                z-index: 2;
            }

            .search-button {
                position: absolute;
                right: 24px;
                top: 0;
                line-height: 40px;
                z-index: 3;

                i:before {
                    line-height: 40px;
                    color: #fff;
                    cursor: pointer;
                }

                &.active {
                    i:before {
                        content:'\0043';
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
            			-moz-transform: rotate(45deg);
            			-o-transform: rotate(45deg);
                    }
                }
            }

            .search-container {
                position:absolute;
                right: 20px;
                bottom: -40px;
                height: 40px;
                overflow: hidden;
                transition: all $transition-out ease-out;
                opacity: 0;
                visibility: hidden;

                &.active {
                    bottom: -40px;
                    transition: all $transition-in ease-in;
                    opacity: 1;
                    visibility: visible;
                }

                form {
                    position: relative;
                    input[type=search] {
                        width: 350px;
                        height: 40px;
                        border: 1px solid $color-borderGrey;
                        border-radius: 0px;
                        box-shadow: none;
                        background-color: #fff;
                        padding-left: 43px;
                        line-height: 40px;
                    }

                    .search-button-container {
                        position: absolute;
                        left: 12px;
                        line-height: 40px;

                        i {
                            position: absolute;
                            left: 0;
                            top: 2px;
                            z-index: 0;
                            font-size: 18px;
                            line-height: 40px;
                        }

                        input[type=submit] {
                            border: none;
                            box-shadow: none;
                            width: 24px;
                            position: relative;
                            cursor: pointer;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    .mobile-menu {
        display: none;
        position: fixed;
        float: left;
        height: 100%;
        width: calc(100% - 57px);
        height: 100%;
        background-color: transparent;
        top: 0;
        left: -100vw;
        z-index: 9999;
        overflow-y: scroll;
        transition: all $transition-out ease-out;

        @media (max-width: 1023px) {
            display: block;
        }

        &.active {
            transform: translateX(100vw);
            transition: all $transition-in ease;
        }

        .mobile-menu-items {
            width: 100%;
            height: 100%;
            background-color: #fff;
            position:relative;
            z-index: 1;

            .search-container {
                float: left;
                width: 100%;
                height: 57px;
                overflow: hidden;

                form {
                    position: relative;

                    input[type=search] {
                        width: 100%;
                        height: 57px;
                        border: none;
                        border-radius: 0px;
                        box-shadow: none;
                        background-color: $color-lightGrey;
                        padding-left: 57px;
                        line-height: 57px;
                    }

                    .search-button-container {
                        position: absolute;
                        left: 22px;
                        line-height: 57px;

                        i {
                            position: absolute;
                            left: 0;
                            top: 3px;
                            z-index: 0;
                            font-size:22px;
                            line-height: 57px;
                        }

                        input[type=submit] {
                            border: none;
                            box-shadow: none;
                            width: 24px;
                            position: relative;
                            cursor: pointer;
                            opacity: 0;
                        }
                    }
                }
            }

            .menu {
                width: 100%;
                float:left;
                height: calc( 100% - 238px);
                overflow-y: scroll;

                .navbar {
                    #navbarNavDropdown {
                        ul.navbar-nav {
                            float: left;
                            width: 100%;
                            padding: 0 9px 0 15px;
                            margin: 0;
                            list-style: none;
                            opacity: 1;

                            li {
                                float: left;
                                width: 100%;
                                height: 57px;

                                a {
                                    position: relative;
                                    float: left;
                                    width: 100%;
                                    height: 57px;
                                    line-height: 57px;
                                    font-family: $font-semibold;
                                    font-size: 17px;
                                    text-align: left;
                                    text-transform: uppercase;
                                    color: $color-dark;
                                }

                                &.dropdown:after {
                                    content:'\0041';
                                    font-family: 'mates';
                                    position: absolute;
                                    right: 16px;
                                    top: -2px;
                                    font-size: 12px;
                                    line-height: 57px;
                                    transform: rotate(-90deg);
                                    -webkit-transform: rotate(-90deg);
                        			-moz-transform: rotate(-90deg);
                        			-o-transform: rotate(-90deg);
                                }
                            }
                        }
                    }
                }
            }

            .mobile-menu-bottom {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 181px;
                background-color: $color-red-mid;

                a.mobile-menu-button {
                    color: #fff;
                    width: 100%;
                    float: left;
                    font-size: 17px;
                    line-height: 57px;
                    font-family: $font-semibold;
                    text-transform: uppercase;
                    padding-left: 24px;
                }

                .mobile-bottom-left {
                    width: 40%;
                    float: left;
                    padding-left: 24px;
                    color: #fff;
                    font-size: 17px;
                    line-height: 57px;
                    font-family: $font-semibold;
                    text-transform: uppercase;
                }

                .mobile-bottom-right {
                    width: 60%;
                    float: left;
                    padding-right: 24px;
                    text-align: right;
                    position: relative;
                    line-height: 57px;

                    a.mobile-menu-button {
                        position: relative;

                        i {
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            background-color: $color-red-hover;
                            display: inline-block;
                            position: relative;
                            left: 0;
                            top: 11px;

                            &::before {
                                color: #fff;
                                font-size: 15px;
                                position: absolute;
                                top: 8px;
                                left: 8px;
                            }
                        }
                    }

                    .social {
                        display: inline-block;
                        color: #fff;
                        margin: 0 10px;
                    }
                }
            }
        }
    }
}

.mobile-close {
    width: 100%;
    height: 100%;
    background-color: rgba(23, 23, 23, 0.8);
    position:fixed;
    right: 0;
    top: 0;
    transition: all $transition-out ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: 21;
    display: none;

    @media (max-width: 1023px) {
        display: block;
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .close-container {
        width: 57px;
        height: 57px;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;

        i:before {
            color: #fff;
            line-height: 56px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            font-size: 24px;
        }
    }
}
