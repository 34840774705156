section.image-cta.contact-banner {
    position: relative;
    div.opacity-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 0;
    }

    .container {
        position: relative;
        .inner-medium {
            .v-wrap {
                .v-box {
                    width: 100%;
                }
            }
        }
    }
}

section.contact-details.column-block {
    margin-bottom: 0;

    @media (max-width: 767px) {
        padding-bottom: 20px!important;
    }

    .container {
        .center {
            width: 100%;
        }

        .column {
            margin-bottom: 30px!important;

                @media (max-width: 1023px) {
                    width: calc(50% - 15px)!important;
                }

                @media (max-width: 767px) {
                    width: 100%!important;
                    margin: 0!important;
                    margin-bottom: 20px!important;
                }

            &:nth-child(4n) {
                margin-right: 0;
            }

            &:nth-child(4n + 1) {
                margin-left: 0;
            }

            &:first-child {
                margin-left: 0;
            }

            @media (max-width: 1023px) {
                &:nth-child(2n) {
                    margin-right: 0;
                }

                &:nth-child(2n + 1) {
                    margin-left: 0;
                }
            }

            .text-container {

                @media (max-width: 767px) {
                    padding: 20px!important;
                }

                h4.entry-title {
                    margin-bottom: 24px;
                    font-weight: 700;
                }
                span.contact-label {
                    font-family: $font-semibold;
                    font-size: 12px;
                    line-height: 16px;
                    color: $color-social-grey;
                    text-transform: uppercase;
                    margin-bottom: 4px;
                }

                a {
                    display: block;
                    margin-bottom: 17px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                p {
                    margin-bottom: 17px;
                }
            }
        }
    }
}
