section.generic-content.article {
    .container {
        .inner-medium {
            .content {
                width: 100%;
                float: left;

                a {
                    text-decoration: underline;
                    color: #316a88;
                }

                a:hover {
                    color: #18455c;
                }
            }

            .image-container {
                margin: 0 -100px 50px;
                width: calc(100% + 200px);
                padding-top: calc(56.25% + 100px);

                @media (max-width: 1023px) {
                    width: calc(100% + 48px);
                    margin: 15px -24px 30px;
                    padding-top: calc(56.25% + 26px);
                }
            }
        }
    }
}

section.generic-introduction.intro-heading {
    padding: 85px 0 0;

    @media (max-width: 767px) {
        padding: 32px 0 0;
    }
}

.content {
    p {
        a {
            text-decoration: underline;
            color: #316a88;
        }

        a:hover {
            color: #18455c;
        }
    }
}
