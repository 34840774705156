section.printContainer {
    display: none;
}

@media print {
    header, footer {
        display: none;
    }

    .footer-bottom {
        display: none;
    }

    section {
        display: none;
    }

    body {
        float: left;
        width: 100%;
        padding: 0;
        margin: 0;

        main {
            float: left;
            width: 100%;
            padding: 0;
            margin: 0;
        }
    }

    section.printContainer {
        display: block;

        .container {
            padding-top: 60px;

            .printHeader {
                width: 100%;
                float: left;
                padding: 10px 0;
                border-bottom: 2px solid $color-dark;
                margin-bottom: 20px;

                img {
                    float: left;
                    width: 100px;
                }
            }

            h1.title {
                font-size: 24px;
                margin-bottom: 10px;
            }

            img {
                display: block;
                width: 50%;
                margin-bottom: 80px;
            }

            .published {
                margin-bottom: 0;
            }
        }
    }
}
